import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import { createPinia, PiniaVuePlugin } from 'pinia';
import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';
import {useCurrentUserStore} from "@/stores/currentUser";
import vidle from "./plugins/vidle";
import CountdownPlus from 'vue-countdown-plus';
import * as Sentry from "@sentry/vue";

Vue.component(CountdownPlus.name, CountdownPlus)

Vue.config.productionTip = false;

Vue.prototype.$axios = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: true,
});

Vue.prototype.$crimAxios = axios.create({
  baseURL: `${process.env.VUE_APP_CRIM_API_URL}/api`,
  withCredentials: true,
});

Vue.prototype.$crimAxios.interceptors.response.use(response => {
  return response;
},error => {
  return error;
});

Vue.prototype.$crimAxios.interceptors.request.use(request => {
  const userStore = useCurrentUserStore();
  request.headers.user = JSON.stringify(userStore.user);
  return request;
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(LaravelPermissionToVueJS);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
});

Sentry.init({
  Vue,
  dsn: "https://ccacd2fd9ef603ca00f49a5eacc75920@o520947.ingest.us.sentry.io/4506859598774272",
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTarget: 'ignore',
      traceRegExp: /localhost/
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://dev.central.internal.cicdata.info", "https://central.internal.cicdata.info"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !window.location.href.startsWith('http://localhost')
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  pinia,
  LaravelPermissionToVueJS,
  vidle,
}).$mount("#app");
