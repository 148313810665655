<template>
  <h1 class="text-center">404</h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFoundView',
}
</script>
