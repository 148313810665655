<template>
  <v-card class="mx-auto my-12" min-width="400" max-width="600">
    <h2 class="text-center">Login</h2>
    <v-form @submit.prevent="login">
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Email"
              placeholder="Email"
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              label="Password"
              placeholder="Password"
              v-model="password"
              :type="'password'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              type="submit"
            >
              Login
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar
      class="mb-4"
      v-model="showSnackbar"
      top
      :color="level"
      :timeout="3000"
    >
      {{ message }}
    </v-snackbar>
  </v-card>
</template>

<script>
// @ is an alias to /src
import {useCurrentUserStore} from "@/stores/currentUser";
import {mapWritableState} from "pinia";
import { Permissions, DashboardPermissions } from "@/js/Permissions";

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      showSnackbar: false,
      level: 'error',
      message: ''
    }
  },
  computed: {
    ...mapWritableState(useCurrentUserStore, ['user']),
  },
  methods: {
    async login() {
      await this.$axios.get('/sanctum/csrf-cookie');

      try {
        const response = await this.$axios.post('/login', {
          email: this.email,
          password: this.password,
        });

        if (response.status === 204) {
          localStorage.setItem('isLoggedIn', 'true');
          this.user = (await this.$axios.get('/user')).data;
          const response = (await this.$axios.get('/permissions')).data.permissions;
          localStorage.setItem('permissions', response);

          const routeToDashboard = DashboardPermissions.some(permission => response.includes(permission))
          const orionAdmin = !response.includes(Permissions.ORION_RESEARCHER) && response.includes(Permissions.ORION);

          if (routeToDashboard || orionAdmin) {
            await this.$router.push({name: 'dashboard'});
            return;
          }

          if (response.includes(Permissions.AIRS)) {
            await this.$router.push({name: 'criminal_index'});
            return;
          }

          if (response.includes(Permissions.LIBRA)) {
            await this.$router.push({name: 'libra_index'});
            return;
          }

          if (response.includes(Permissions.ORION_RESEARCHER)) {
            if(this.orionPasswordExpired()) {
              await this.$router.push(`/orion/researchers/${this.user.orionResearcherId}`);
              return;
            }

            await this.$router.push({name: 'orion_editqueue_index'});
          }
        }
      } catch (e) {
        if (e.response?.status === 422) {
          this.showSnackbar = true;
          this.message = 'Invalid credentials, please try again.';
        }
      }
    },
    orionPasswordExpired() {
      return !(this.user?.passwordExpiration['expiring'] === false
        || this.user?.passwordExpiration['daysToExpiration'] > 0);
    }
  }
}
</script>
