<template>
  <div>
    <slot name="activator"/>
    <v-dialog :retain-focus="false" v-model="showIdleWarning" width="500">
      <BaseDialog :title="title" :actions="actions" :titleClass="custom" v-if="showIdleWarning">
        <template v-slot:content>
          <div class="text-center">
             <countdown-plus :time="301000" format="mm:ss" ref="countdown"/>
          </div>
        </template>
      </BaseDialog>
    </v-dialog>
  </div>
</template>

<script>
import BaseDialog from "@/components/BaseDialog";
import {mapWritableState} from 'pinia'
import { useIdleStore } from "@/stores/idle";

export default {
  components: {BaseDialog},
  name: "IdleWarningDialog",
  data() {
    //countdown is in milliseconds
    //300000 is 5 mins, 1 extra second to account for lag
    return {
      title: 'Idle Warning',
      custom: 'error--text font-weight-bold',
      interval: null,
      actions: [
        {
          label: "I'm still here",
          action: this.stillHere,
        },
      ]
    }
  },
  computed: {
    ...mapWritableState(useIdleStore, ['showIdleWarning']),
  },
  methods: {
    stillHere() {
      this.showIdleWarning = false;
    },
    open() {
      this.showIdleWarning = true;
    }
  },
};
</script>

<style scoped>

</style>