<template>
  <div>
    <v-card>
      <h4 class="text-uppercase text-center py-4" :class="titleClass">{{ title }}</h4>
      <h5 v-if="subTitle" class="text-center">{{subTitle}}</h5>
      <v-container>
        <slot name="content"></slot>
      </v-container>
      <v-card-actions class="justify-center">
        <v-btn
          v-show="showAction(index)"
          :color="action.color || 'primary'"
          :class="action.class"
          v-for="(action, index) in actions"
          :loading="action.loading || false"
          :disabled="action.loading || false"
          :key="action.label"
          @click="action.action"
        >
          {{ action.label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['title', 'actions', 'subTitle', 'titleClass'],
  name: "BaseDialog",
  data: () => ({

  }),
  computed: {
    showAction() {
      return index => this.actions[index].show ?? true;
    },
  },
};
</script>

<style scoped>

</style>