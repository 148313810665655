<template>
  <div id="app">
    <v-app>
      <IdleComponentVue v-show="false"/>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import IdleComponentVue from './components/IdleComponent.vue';

export default {
  components: { IdleComponentVue },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.blue-text {
  input, textarea, .v-select__selections {
    color: #2538E3 !important;
  }
}

.blue-span {
  span {
    color: #2538E3 !important;
  }
}

.blue-color {
  color: #2538E3 !important;
}

.purple-text {
  input, textarea, .v-select__selections {
    color: #7b1fa2 !important;
  }
}

.purple-color {
  color: #7b1fa2 !important;
}

.green-color {
  color: #006633;
}

.orange-text {
  color: #e8833a !important;
}

.roleError {
  color: #ff5252;
}

.hidden {
  visibility: hidden;
}

.buttonMatchInput {
  height: 39px !important;
}

.orion-researcher-dark-green {
  color: #207868 !important;
}

.orion-researchers-light-green {
  color: #d2e4e1 !important;
}
</style>
