<template>
  <div> 
    <v-idle
    @idle="onIdle"
    @remind="onRemind"
    :reminders="[300]"
    :duration="1800">
    </v-idle>

   <IdleWarningDialog ref="idleWarning"/>
  </div>
</template>

<script>
import IdleWarningDialog from './IdleWarningDialog.vue';
import {mapWritableState} from 'pinia'
import { useIdleStore } from "@/stores/idle";

export default {
  //v-idle is in seconds
  //Reminder: 300 seconds is 5 mins
  //Duration: 1,800 is 30 minutes
  name: "IdleComponent",
  components: { IdleWarningDialog },
  methods: {
      onIdle() {
        this.showIdleWarning = false;
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('permissions');
        localStorage.removeItem('passwordExpiringAcknowledged');
        this.$router.push({name: 'login'});
      },
      onRemind() {
        this.$refs.idleWarning.open();
      }
    },
  computed: {
    ...mapWritableState(useIdleStore, ['showIdleWarning']),
  },
}
</script>

<style scoped>

</style>
